import { useLocation } from 'react-router-dom'

import {
  setIsCookiesModal,
  setIsPrivacyPolicyModal,
  setModals,
} from 'app/store/actions'
import useIsMobileModal from 'features/modal/use-cases/useIsMobileModal'
import { SubmitAffirmationModal } from 'features/affirmation/SubmitAffirmationModal'
import Layout from 'widgets/_layout'
import Menu from 'widgets/header/menu'
import { ROUTES } from 'shared/config/routes'
import { setAllObjValuesFalse } from 'shared/utils/functions'
import classNames from 'shared/lib/class-names'
import ArrowLeftIcon from 'shared/assets/icons/arrow-left.webp'
import PhoneIcon from 'shared/assets/icons/PhoneIcon.webp'
import { Logo } from 'shared/ui/logo'

import cls from './Header.module.scss'

export default function Header() {
  const { isMobile, isMobileModal, modals, setIsMobileModal, dispatch } =
    useIsMobileModal()

  const { pathname } = useLocation()
  const isRegisteredPage = pathname === ROUTES.REGISTERED

  const closeAllModals = () => {
    const isAffirmationAndPolicyModalOpened =
      modals.isAffirmationModal &&
      (modals.isCookiesModal || modals.isPrivacyPolicyModal)

    if (isAffirmationAndPolicyModalOpened) {
      dispatch(setIsCookiesModal(false))
      dispatch(setIsPrivacyPolicyModal(false))
    } else {
      const objModalsFalseValues = setAllObjValuesFalse(modals)

      dispatch(setModals(objModalsFalseValues))

      setIsMobileModal(false)
    }
  }

  return (
    <header
      className={classNames(
        cls.header,
        isMobileModal ? cls['header--fixed'] : null,
      )}
    >
      <Layout
        className={classNames(cls.layout, isMobileModal && cls.layout__modal)}
      >
        <div className={cls.left_block}>
          {isMobileModal && !isRegisteredPage && (
            <img
              className={cls.header__left_icon}
              src={ArrowLeftIcon}
              alt="arrow-left"
              onClick={closeAllModals}
            />
          )}

          <Logo className={classNames(isMobileModal && cls.logo__modal)} />

          {!isMobile && !isRegisteredPage && <Menu />}
        </div>

        <div className={cls.right_block}>
          {!isMobileModal && (
            <div className={cls.call_info}>
              <img src={PhoneIcon} alt="PhoneIcon" />
              <p>бесплатно с мобильных операторов Беларуси</p>
            </div>
          )}

          {!isMobile && !isRegisteredPage && (
            <SubmitAffirmationModal btnProps={{ variant: 'variant2' }} />
          )}
        </div>
      </Layout>
    </header>
  )
}

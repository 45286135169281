import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom'
import { ROUTES } from 'shared/config/routes'
import { MainLayout } from 'layouts/MainLayout'
import Home from 'pages/home'
import { RegisteredPage } from 'pages/RegisteredPage'

export const Router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={ROUTES.MAIN} element={<MainLayout />}>
        <Route index={true} element={<Home />} />
        <Route path={ROUTES.REGISTERED} element={<RegisteredPage />} />
      </Route>
    </>,
  ),
)

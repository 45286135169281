import LogoIcon from 'shared/assets/icons/logo-full.webp'
import styles from './Logo.module.scss'
import classNames from 'classnames'

interface Logo {
  className?: string
}

export function Logo(props: Logo) {
  const { className } = props

  return (
    <a href="/" className={classNames(styles.logo, className)}>
      <img src={LogoIcon} alt="logo" />
    </a>
  )
}

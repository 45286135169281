import React, { Dispatch, useEffect } from 'react'

import type { IAction } from 'app/store/model/action-types'
import type { IUser } from 'entities/user/model/user'
import {
  setIsAffirmationModal,
  setIsCookiesModal,
  setIsPrivacyPolicyModal,
  setIsSuccessAffirmationModal,
  setUserAccepted,
  setUserInfo,
} from 'app/store/actions'
import type { IFormInputs } from 'features/affirmation/_model/IFormInputs'
import InputMask from 'shared/lib/input-mask'

import { useForm } from 'shared/lib/react-hook-form'
import { yupResolver } from 'shared/lib/yup-resolver'
import classNames from 'shared/lib/class-names'
import { Button } from 'shared/ui/button'

import schema from '../_schema/schema'
import AcceptModal from '../_ui/accept-modal/accept-modal'

import cls from './Form.module.scss'
import { generateEmail } from 'features/affirmation/_use-cases/generate-email'
import { sendUserData } from 'features/affirmation/_use-cases/send-user-data'
import { SelectCallHours } from 'features/affirmation/select-call-hours/SelectCallHours'
import { PHONE_MASK, PHONE_TITLE } from 'features/affirmation/_model/constants'
import { phoneMaskChange } from 'features/affirmation/_use-cases/phone-mask-change'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'shared/config/routes'

interface IAffirmationFormProps {
  user: IUser
  dispatch: Dispatch<IAction>
  title?: string
  description?: string
  className?: string
  isAffirmationModal?: boolean
}

export function AffirmationForm(props: IAffirmationFormProps) {
  const { user, dispatch, title, description, isAffirmationModal, className } =
    props

  const navigate = useNavigate()

  const {
    setValue,
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitSuccessful },
  } = useForm<IFormInputs>({
    defaultValues: {
      phoneNumber: '',
      isPolitics: true,
      isAge: true,
      whatsapp: true,
      country: user.api.country,
      ip: user.api.ip,
      external_id: user.searchParams.external_id,
      metric: user.searchParams.metric,
      gaid: user.searchParams.gaid,
      sub1: user.searchParams.sub1,
      sub2: user.searchParams.sub2,
      sub3: user.searchParams.sub3,
      sub4: user.searchParams.sub4,
      sub5: user.searchParams.sub5,
      sub6: user.searchParams.sub6,
      sub7: user.searchParams.sub7,
      apps_id: user.searchParams.apps_id,
      devKey: user.searchParams.devKey,
      bundle: user.searchParams.bundle,
      status: user.searchParams.status,
    },
    // @ts-ignore
    resolver: yupResolver(schema),
  })

  const openCookiesModal = () => {
    dispatch(setIsCookiesModal(true))
  }
  const openPrivacyPolicyModal = () => {
    dispatch(setIsPrivacyPolicyModal(true))
  }
  const openAcceptModal = () => {
    dispatch(setIsSuccessAffirmationModal(true))
  }
  const closeAffirmationModal = () => {
    dispatch(setIsAffirmationModal(false))
  }
  const onChecked = (e: any, id: any) => {
    const value = e?.target?.checked

    setValue(id, value)

    dispatch(setUserAccepted({ [id]: value }))
  }

  const onSubmit = handleSubmit(async (data: any) => {
    // query params
    setValue('external_id', user.searchParams.external_id)
    setValue('metric', user.searchParams.metric)
    setValue('gaid', user.searchParams.gaid)
    setValue('sub1', user.searchParams.sub1)
    setValue('sub2', user.searchParams.sub2)
    setValue('sub3', user.searchParams.sub3)
    setValue('sub4', user.searchParams.sub4)
    setValue('sub5', user.searchParams.sub5)
    setValue('sub6', user.searchParams.sub6)
    setValue('sub7', user.searchParams.sub7)
    setValue('apps_id', user.searchParams.apps_id)
    setValue('devKey', user.searchParams.devKey)
    setValue('bundle', user.searchParams.bundle)
    setValue('status', user.searchParams.status)

    setValue('isPolitics', user.accepted.isPolitics)
    setValue('isAge', user.accepted.isAge)
    setValue('whatsapp', user.accepted.whatsapp)
    setValue('telegram', user.accepted.telegram)
    setValue('viber', user.accepted.viber)

    await sendUserData({
      ...data,
      country: user.api.country,
      ip: user.api.ip,
    })

    dispatch(
      setUserInfo({
        firstName: data?.firstName,
        lastName: data?.lastName,
        phoneNumber: data?.phoneNumber,
        email: data?.email,
        callHours: data?.callHours,
      }),
    )

    closeAffirmationModal()
    // openAcceptModal()

    navigate(ROUTES.REGISTERED)
  })

  useEffect(() => {
    if (isSubmitSuccessful) {
      dispatch(
        setUserAccepted({
          isPolitics: true,
          isAge: true,
          whatsapp: true,
          telegram: false,
          viber: false,
        }),
      )

      reset()
    }
  }, [isSubmitSuccessful, reset])

  const modalId = isAffirmationModal ? '-modal' : ''

  let callOptions = [
    { label: 'с 8:00 до 12:00', value: 'с 8:00 до 12:00' },
    { label: 'с 12:00 до 16:00', value: 'с 12:00 до 16:00' },
    { label: 'с 16:00 до 20:00', value: 'с 16:00 до 20:00' },
  ]
  const handleSelectChange = (selectedOption: any) => {
    setValue('callHours', selectedOption.value)
  }

  return (
    <form
      // @ts-ignore
      onSubmit={ handleSubmit(onSubmit) }
      className={ classNames(cls.form, className) }
    >
      <h3 className={ cls.form__title }>{ title }</h3>

      { description && <p className={ cls.form__desc }>{ description }</p> }

      <div className={ cls.form__names }>
        <div className={ cls.form__input_block }>
          <input
            { ...register('firstName') }
            id={ 'firstName' + modalId }
            name={ 'firstName' }
            placeholder="Введите ваше имя"
            type="text"
          />
          { errors?.firstName && (
            <p className={ cls.errors }>{ errors.firstName.message }</p>
          ) }
        </div>

        <div className={ cls.form__input_block }>
          <input
            { ...register('lastName') }
            id={ 'lastName' + modalId }
            name={ 'lastName' }
            placeholder="Введите вашу фамилию"
            type="text"
          />
          { errors?.lastName && (
            <p className={ cls.errors }>{ errors.lastName.message }</p>
          ) }
        </div>
      </div>

      <div className={classNames(
        cls.form__input_block,
        cls['form__input_block--tel'],
      )}>
        <InputMask
          { ...register('phoneNumber', { required: true }) }
          className={ cls.inputPhone }
          id={ 'phoneNumber' + modalId }
          name={ 'phoneNumber' }
          type="tel"
          placeholder={ `${ PHONE_TITLE } Мобильный телефон` }
          mask={ PHONE_MASK }
          maskPlaceholder=""
          alwaysShowMask={ false }
          beforeMaskedStateChange={ phoneMaskChange }
        />
        { errors?.phoneNumber && (
          <p className={ cls.errors }>{ errors.phoneNumber.message }</p>
        ) }
      </div>

      <div className={ cls.form__input_block }>
        <input
          { ...register('email') }
          id={ 'email' + modalId }
          name={ 'email' }
          placeholder="Введите ваш e-mail"
          type="text"
        />
        { errors?.email && <p className={ cls.errors }>{ errors.email.message }</p> }
      </div>

      <SelectCallHours
        callOptions={ callOptions }
        handleSelectChange={ handleSelectChange }
      />

      <>
        <input
          type="hidden"
          { ...register('callHours') }
          name="callHours"
          defaultValue={ callOptions[1].value }
        />
        <input
          { ...register('country') }
          id={ 'country' + modalId }
          name={ 'country' }
          type="hidden"
        />
        <input
          { ...register('ip') }
          id={ 'ip' + modalId }
          name={ 'ip' }
          type="hidden"
        />
        <input
          { ...register('external_id') }
          id={ 'external_id' + modalId }
          name={ 'external_id' }
          type="hidden"
        />
        <input
          { ...register('metric') }
          id={ 'metric' + modalId }
          name={ 'metric' }
          type="hidden"
        />
        <input
          { ...register('gaid') }
          id={ 'gaid' + modalId }
          name={ 'gaid' }
          type="hidden"
        />
        <input
          { ...register('sub1') }
          id={ 'sub1' + modalId }
          name={ 'sub1' }
          type="hidden"
        />
        <input
          { ...register('sub2') }
          id={ 'sub2' + modalId }
          name={ 'sub2' }
          type="hidden"
        />
        <input
          { ...register('sub3') }
          id={ 'sub3' + modalId }
          name={ 'sub3' }
          type="hidden"
        />
        <input
          { ...register('sub4') }
          id={ 'sub4' + modalId }
          name={ 'sub4' }
          type="hidden"
        />
        <input
          { ...register('sub5') }
          id={ 'sub5' + modalId }
          name={ 'sub5' }
          type="hidden"
        />
        <input
          { ...register('sub6') }
          id={ 'sub6' + modalId }
          name={ 'sub6' }
          type="hidden"
        />
        <input
          { ...register('sub7') }
          id={ 'sub7' + modalId }
          name={ 'sub7' }
          type="hidden"
        />
        <input
          { ...register('apps_id') }
          id={ 'apps_id' + modalId }
          name={ 'apps_id' }
          type="hidden"
        />
        <input
          { ...register('devKey') }
          id={ 'devKey' + modalId }
          name={ 'devKey' }
          type="hidden"
        />
        <input
          { ...register('bundle') }
          id={ 'bundle' + modalId }
          name={ 'bundle' }
          type="hidden"
        />
        <input
          { ...register('status') }
          id={ 'status' + modalId }
          name={ 'status' }
          type="hidden"
        />
      </>

      <Button type="submit">Оставить заявку</Button>

      <label className={ cls.checkSmallLabel } htmlFor="politics">
        <input
          { ...register('isPolitics') }
          checked={ isAffirmationModal && user.accepted.isPolitics }
          onChange={ (e) => onChecked(e, 'isPolitics') }
          className={ cls.checkSmall }
          type="checkbox"
        />
        <span className={ cls.checkSmallLabelText }>
          <>
            Я согласен с{ ' ' }
            <span onClick={ openPrivacyPolicyModal }>
              политикой конфиденциальности
            </span>{ ' ' }
            и{ ' ' }
            <span onClick={ openCookiesModal }>
              даю согласие на обработку моих персональных данных
            </span>
          </>
        </span>
      </label>
      <label className={ cls.checkSmallLabel } htmlFor="age">
        <input
          { ...register('isAge') }
          checked={ isAffirmationModal && user.accepted.isAge }
          onChange={ (e) => onChecked(e, 'isAge') }
          className={ cls.checkSmall }
          type="checkbox"
        />
        <span className={ cls.checkSmallLabelText }>
          Я подтверждаю, что мне больше 21-го года
        </span>
      </label>

      <h4 className={ cls['form__title-mess'] }>
        Выберите мессенджер для обратной связи
      </h4>
      <div className={ cls.form__messengers }>
        <div className={ cls.checkboxRect }>
          <input
            { ...register('whatsapp') }
            checked={ isAffirmationModal && user.accepted.whatsapp }
            onChange={ (e) => onChecked(e, 'whatsapp') }
            id={ 'whatsapp' + modalId }
            type="checkbox"
            name="whatsapp"
          />
          <label htmlFor={ 'whatsapp' + modalId }>Whatsapp</label>
        </div>
        <div className={ cls.checkboxRect }>
          <input
            { ...register('telegram') }
            checked={ isAffirmationModal && user.accepted.telegram }
            onChange={ (e) => onChecked(e, 'telegram') }
            id={ 'telegram' + modalId }
            type="checkbox"
            name="telegram"
          />
          <label htmlFor={ 'telegram' + modalId }>Telegram</label>
        </div>
        <div className={ cls.checkboxRect }>
          <input
            { ...register('viber') }
            checked={ isAffirmationModal && user.accepted.viber }
            onChange={ (e) => onChecked(e, 'viber') }
            id={ 'viber' + modalId }
            type="checkbox"
            name="viber"
          />
          <label htmlFor={ 'viber' + modalId }>Viber</label>
        </div>
      </div>

      <AcceptModal />
    </form>
  )
}

import useAppContext from 'app/store/useAppContext'
import classNames from 'shared/lib/class-names'
import TreeImg from 'shared/assets/images/invest/tree.webp'

import { cardsContent } from './_model/cards-content'
import Card from './_ui/Card'
import Cards from './_ui/Cards'

import cls from './OurAdvantages.module.scss'

export default function OurAdvantages() {
  const {
    store: { isMobile, isMobileSmall },
  } = useAppContext()

  return (
    <section
      className={classNames('flex__col_center', cls.page)}
      id="ourAdvantages"
    >
      <div className={classNames(cls.page__container, 'container__desktop')}>
        <div className={cls.left_block}>
          <div className={cls.left_block__content}>
            <h2 className={cls.title}>
              {isMobileSmall ? (
                <>Ваши выгоды</>
              ) : (
                <>
                  <span>Вкладывайте</span>и зарабатывайте
                  <span>не выходя из дома</span>
                </>
              )}
            </h2>
            {!isMobile && <img src={TreeImg} alt="TreeImg" />}
          </div>
        </div>
        <div className={cls.right_block}>
          {!isMobile && (
            <div className={cls.cards__desktop}>
              <div>
                <Card {...cardsContent?.[0]} isSliderDesc2={false} />
                <Card {...cardsContent?.[2]} isSliderDesc2={false} />
              </div>
              <div>
                <Card {...cardsContent?.[1]} isSliderDesc2={false} />
                <Card {...cardsContent?.[3]} isSliderDesc2={false} />
              </div>
            </div>
          )}
          {isMobile && <Cards isSliderDesc2={true} />}
        </div>
      </div>
    </section>
  )
}

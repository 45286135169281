import Promotion from 'pages/home/promotion'
import OurAdvantages from 'pages/home/our-advantages'
import { FirstDividends } from 'pages/home/first-dividends'
import About from 'pages/home/about'
import ProtectMoney from 'pages/home/protect-money'
import HovInvest from 'pages/home/how-invest'
import Contacts from 'pages/home/contacts'
import classNames from 'shared/lib/class-names'

import cls from './Home.module.scss'

export default function Home() {
  return (
    <div className={classNames(cls.home)}>
      <Promotion />
      <OurAdvantages />
      <About />
      <FirstDividends />
      <ProtectMoney />
      <HovInvest />
      <Contacts />
    </div>
  )
}

import styles from './Menu.module.scss'

export default function Menu() {
  return (
    <nav className={styles.menu}>
      <ul>
        <li>
          <a href="#about">О нас</a>
        </li>
        <li>
          <a href="#hovInvest">FAQ</a>
        </li>
        <li>
          <a href="#contacts">Контакты</a>
        </li>
      </ul>
    </nav>
  )
}

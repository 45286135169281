import useAppContext from 'app/store/useAppContext'
import { Slider } from 'shared/ui/slider'

import { cardsContent } from '../_model/cards-content'
import Card from './Card'

import cls from './Cards.module.scss'

interface Cards {
  isSliderDesc2?: boolean
}

export default function Cards(props: Cards) {
  const { isSliderDesc2 = false } = props

  const {
    store: { isMobile },
  } = useAppContext()

  const cards = cardsContent.map((card) => {
    return (
      <Card
        key={card?.id}
        id={card?.id}
        icon={card?.icon}
        title={card?.title}
        desc1={card?.desc1}
        desc2={isSliderDesc2 ? card?.desc2 : undefined}
      />
    )
  })

  return (
    <>
      {isMobile ? (
        <Slider className={cls.cards_slider}>{cards}</Slider>
      ) : (
        <ul className={cls.cards}>{cards}</ul>
      )}
    </>
  )
}
